import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useFetchData } from "./hooks";
const defaultDevice = "esp32_EF38EC";

export default function MediaCard({ card }) {
  const { title, buttonText, description, method, device, image } = card;
  const [res, apiMethod] = useFetchData();
  console.log(res);
  const { isLoading } = res;

  console.log("isLoading", isLoading);
  
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia component="img" height="280" image={image} alt="radio" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{ ml: "auto" }}
          variant="outlined"
          onClick={() => apiMethod(`/${method}/${device || defaultDevice}/5`)}
          size="small"
        >
          {buttonText}
        </Button>
        {/* <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  );
}
