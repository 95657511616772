import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import MediaCard from "./MediaCard";
import radio from "./radio.jpg";
import redRelay from "./red-relay.jpeg";
import sms from "./sms.jpg";
import blueRelay from "./blue-relay.jpeg";
import logo from "./hala-logo.png";
import { useFetchData } from "./hooks";

function Copyright() {
  return (
    <Typography
      sx={{ mt: 2, mb: 3 }}
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://halasystems.com/">
        Hala Systems
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App({signOut}) {
  const [res, apiMethod] = useFetchData();
  console.log('res', res);
  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          mt: 1,
        }}
      >
        <Button onClick={signOut} align="right" variant="outlined">
          Sign Out
        </Button>
      </Box>
      <Box
        component="img"
        sx={{
          display: "flex",
          alignItems: "center",
          height: "auto",
          width: 320,
          my: 6,
          mx: "auto",
        }}
        alt="The house from the offer."
        src={logo}
      />
      <Box sx={{ my: 1 }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent={"space-evenly"}
        >
          {cardInfo.map((card) => (
            <Grid key={card.title} item>
              <MediaCard card={card} />
            </Grid>
          ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            mt: 1,
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={() => apiMethod("/critical/+14789986278/5")}
          >
            Critical
          </Button>
        </Box>
        <Copyright />
      </Box>
    </Container>
  );
}

const cardInfo = [
  {
    title: "SMS Message",
    buttonText: "Send",
    description:
      "Demonstrates the ability to issue warnings and messages via SMS to a mobile phone.",
    method: "sms-warning",
    device: "+14789986278",
    online: true,
    image: sms,
  },
  {
    title: "Radio",
    buttonText: "Activate",
    description:
      "Demonstrates the ability to broadcast messages over different radio band frequencies.",
    method: "radio-warning",
    device: "esp32_EF38EC",
    online: false,
    image: radio,
  },
  {
    title: "Blue Relay",
    buttonText: "Activate",
    description:
      "Visual warning light used to indicate a number of adverse conditions.",
    method: "relay-warning",
    device: "esp32_C1AD4C",
    online: true,
    image: blueRelay,
  },
  {
    title: "Red Relay",
    buttonText: "Activate",
    description:
      "Visual warning light used to indicate a number of critical conditions.",
    method: "relay-warning",
    device: "esp32_EF38EC",
    online: true,
    image: redRelay,
  },
];