// import { useState, useCallback } from "react";
// import axios from "axios";

// export const useFetchData = ({ url, headers, payload }) => {
//   const [res, setRes] = useState({ data: null, error: null, isLoading: false });
//   // const [error, setError]
//   // You POST method here
//   const callAPI = useCallback(() => {
//     setRes((prevState) => ({ ...prevState, isLoading: true }));
//     axios
//       .post(url, headers, payload)
//       .then((res) => {
//         setRes({ data: res.data, isLoading: false, error: null });
//       })
//       .catch((error) => {
//         setRes({ data: null, isLoading: false, error });
//       });
//   }, [url, headers, payload]);
//   return [res, callAPI];
// };

import { useState, useCallback } from "react";
import { API } from "aws-amplify";

export const useFetchData = () => {
  const [res, setRes] = useState({ data: null, error: null, isLoading: false });
  // const [error, setError]
  // You POST method here

  const callAPI = useCallback((path) => {
    setRes((prevState) => ({ ...prevState, isLoading: true }));
    API.post("default", path)
      .then((res) => {
        // console.log('then', res);
        setRes({ data: res, isLoading: false, error: null });
      })
      .catch((error) => {
        console.log("err");
        setRes({ data: null, isLoading: false, error });
      });
  }, []);
  return [res, callAPI];
};

